import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Modal, ProgressBar, Table, Button, Form } from "react-bootstrap";
import execlIcons from "../../../src/pages/components/assets/icons/excelIcons.png";
import {
  ApiEndPoint,
  decodeToken,
} from "../../../src/pages/components/utils/utlis";
import { BsCheck2Circle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Broadcastdata } from "../../Redux Toolkit/slice";
import dropzoneStyle from "./dropdwon.module.css"

const ExcelUploadPopup2 = ({ isOpen, onClose }) => {
  var dispatch = useDispatch();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [responseRows, setResponseRows] = useState([]);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const handleShowResponseModal = () => setShowResponseModal(true);
  const handleCloseResponseModal = () => setShowResponseModal(false);
  const [broadcastName, setBroadCastName] = useState(""); // State for list name
  const [selectedFile, setSelectedFile] = useState(null);
  const [isBroadCreated, setIsbroadCreat]=useState(false);
  const [isAlreadyExist,setAlreadExisit]=useState(false);
  const jwtToken = sessionStorage.getItem("jwtToken");
  const broadcastData= useSelector((state)=>state.Data.broadcastdata);

  const decode = decodeToken(jwtToken);
  const { decodedToken } = decode;
  const { userId } = decodedToken;
  console.log("USERID:", userId);

  const fetchBroadcastData = useCallback(async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/broadcasts`);
      dispatch(Broadcastdata(response.data.broadcasts));
    } catch (error) {
      console.error('Error fetching broadcast data:', error);
    }
  }, [dispatch]);
  
 useEffect(()=>{
  if (!broadcastData) {
    fetchBroadcastData();
  }
 },[broadcastData, fetchBroadcastData]);
 const CheckBroadcast=async (checkName)=>{

  
  if (Array.isArray(broadcastData) && broadcastData.length > 0) {
    for (let i = 0; i < broadcastData.length; i++) {
      if (broadcastData[i].name === checkName) {
        setAlreadExisit(true);
        return true;
      }
      else{
        setAlreadExisit(false);
      }
    }
  }
  // setSelectedFile(null);
  // setBroadCastName("");

 }
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileTypeError(false);
    if (
      file &&
      (file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setSelectedFile(file); // Set selected file in state
    } else {
      setFileTypeError(true);
    }
  }, []);

  const handleListNameChange = (e) => {
    CheckBroadcast(e.target.value);
    setBroadCastName(e.target.value);
  };
  const uploadFile = async () => {
    try {
      setUploading(true); // Start uploading
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("broadcastName", broadcastName);
      formData.append("adminID", userId);

      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        `${ApiEndPoint}/uploadFromExcel`,
        formData,
        config
      );

      console.log(response.data);

      if (response.status === 200) {
        console.log("create broadcast data:",response.data);
        setIsbroadCreat(true);
        setSelectedFile(null);
        
        if (response.data.length > 0) {
          setResponseRows(response.data);
          
          handleShowResponseModal();
          const respons = await axios.get(`${ApiEndPoint}/broadcasts`);
          setSelectedFile(null);
          setBroadCastName("");
          dispatch(Broadcastdata(respons.data.broadcasts));
        } else {
          // Show the response modal
          setUploadProgress(0); // Reset upload progress
        }
        // Update response rows with the data from the server
      }
     
    } catch (error) {
      console.error("Error uploading file:", error);
      window.alert("Error uploading file. Please try again.");
    } finally {
      setUploading(false); // Stop uploading
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
    maxFiles: 1,
  });
  const [stripedRows, setStripedRows] = useState(true); // State to track alternating row colors

  const toggleStripedRows = () => {
    setStripedRows(!stripedRows);
  };
 
  return (
    <>
      {/* Response Modal */}
      <style>
        {`
          .form-control:focus {
            border-color: green !important;
            box-shadow: 0 0 0 0.25rem rgba(0, 128, 0, 0.25) !important;
          }
          .form-control.is-invalid {
            border-color: red !important;
          }
        `}
      </style>
      <Modal
        show={showResponseModal}
        onHide={handleCloseResponseModal}
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Error in Uploading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="justify-content-center text-danger">
            <p>The following rows were not inserted as they already exist or have no Phone #:</p>
          </div>
          {responseRows.length > 0 ? (
            <Table bordered={false} hover>
              <thead>
                <tr>
                  <th>Row #</th>
                  <th>Name</th>
                  <th>Phone #</th>
                </tr>
              </thead>
              <tbody>
                {responseRows.map((row, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: index % 2 === 0 ? "#F08080" : "#dc3545",
                      }}
                    >
                      {row.rowNum}
                    </td>
                    <td
                      style={{
                        backgroundColor: index % 2 === 0 ? "#F08080" : "#dc3545",
                      }}
                    >
                      {row.name}
                    </td>
                    <td
                      style={{
                        backgroundColor: index % 2 === 0 ? "#F08080" : "#dc3545",
                      }}
                    >
                      {row.phoneNumber}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data to display.</p>
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="secondary border border-none"
            onClick={handleCloseResponseModal}
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "10px 20px",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  
      {/* Create Broadcast Form */}
      <div className="create-broadcast-container">
      <Form.Group controlId="listName">
      <Form.Control
    
        type="text"
        placeholder="Enter Broadcast Name"
        value={broadcastName}
        onChange={handleListNameChange}
        style={{
          borderColor: isAlreadyExist ? 'red' : '', // Red border if already exists
          boxShadow: isAlreadyExist ? '' : '',
           // Remove any existing shadows
        }}
        
        className={isAlreadyExist ? 'is-invalid' : ''}
      />
      {isAlreadyExist && (
        <Form.Text style={{ color: 'red' }}>
          Broadcast Already Exists
        </Form.Text>
      )}
    </Form.Group>
  
        <div {...getRootProps()} className="dropzone-container mt-1">
          <input {...getInputProps()} />
          <div className="icon-container">
            <img src={execlIcons} alt="Excel" className="excel-icon" />
            {uploading && uploadProgress === 100 && (
              <BsCheck2Circle className="check-icon text-success" />
            )}
          </div>
          <p className="dropzone-text">
            Drag and drop an Excel file here, or click to select one
          </p>
          {isBroadCreated&&(
            <p className="dropzone-text text-custom-green">
             {isBroadCreated&&(
            <p className="dropzone-text text-custom-green">
              Broadcast Successfully Created
          </p>
          )}
          </p>
          )}
          
          {fileTypeError ? (
            <p className="text-danger">Please upload .xls or .xlsx only.</p>
          ) : (
            selectedFile && <p>File Name: {selectedFile.name}</p>
          )}
  
          <style jsx>{`
            .dropzone-container {
              border: 2px dashed #24D366;
              padding: 40px; /* Increased padding for bigger size */
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
  
            .icon-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: relative;
              margin-bottom: 20px; /* Adjusted margin */
            }
  
            .excel-icon {
              min-height: 50px;
              min-width: 60px;
              color: goldenrod;
            }
  
            .check-icon {
              color: green;
              min-height: 25px;
              min-width: 25px;
              height: 50px;
              width: 50px;
              position: absolute;
              top: 0;
              right: 0;
            }
  
            .dropzone-text {
              margin-bottom: 20px;
              font-size: 18px; /* Increased font size */
            }
            


          `}</style>
        </div>
  
        {/* Upload button */}
        <div className="d-flex justify-content-center align-items-center">
          <Button
            style={{
              marginTop: 6,
              padding: "10px 20px",
              backgroundColor: "#A66CFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              //width: "100%",
              //maxWidth: "100%",
            }}
            className="mt-1 justify-content-center"
            variant="primary"
            disabled={!broadcastName||!selectedFile||isAlreadyExist}
            onClick={uploadFile}
          >
            Create
          </Button>
        </div>
      </div>
    </>
  );
  
};

export default ExcelUploadPopup2;

