import React, { useEffect, useState } from "react";
import "../index.css";
import "reactflow/dist/style.css";
import { ApiEndPoint } from "../../pages/components/utils/utlis";
import newchatbot from "../../pages/components/assets/icons/chatbot.png";
import { MdDelete } from "react-icons/md";
import Template from "../../pages/components/assets/icons/Template.png";
import Button from "../../pages/components/assets/icons/Button.png";
import Message from "../../pages/components/assets/icons/Message.png";
import axios from "axios";
import { FaCloudUploadAlt, FaToggleOn } from "react-icons/fa";
import { LuRefreshCcw } from "react-icons/lu";
import "./botbuikder.css"
import { BsPersonBadge } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import Modal from "react-modal";
Modal.setAppElement('#root');

export default function Sidebarlist({ onSave, onNew, onSaveFlow, onRestoreFlow, onDeleteNode, visible }) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [bot, setBot] = useState('');
  const [status, setStatus] = useState(false);
  const [temp, setTemp] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  useEffect(() => {
    if (bot && items) {
      if (items && Array.isArray(items["localJsonData"])) {
        const selected = items["localJsonData"].find(item => item["name"] === bot);
        setStatus(selected.status ? selected.status : false)
        setTemp(selected.Templates ? selected.Templates : null)
        setSelectedItem([bot, selected.status, selected.Templates] || null);

      }
    }
  }, [items, bot, status, temp]);

  const fetchBotData = async (botId) => {
    try {
      const response = await fetch(
        `${ApiEndPoint}/getBotById/${botId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching bot data:", error);
      return { error: "Error fetching bot data" };
    }
  };
  const handleCancelLogout = () => {
    setIsPopupOpen(false); // Close the popup without logging out
  };
  const handleConfirmLogout = () => {
    setIsPopupOpen(false); // Close the popup
    handleDelete() // Call the logout function
  };
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    console.log("node type", nodeType)
    event.dataTransfer.effectAllowed = "move";
  };

  const handleClick = async (item) => {
    setBot(item);

    const fetchedData = await fetchBotData(item);
    const rec = [item, fetchedData]
    onSave(rec);
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  const handleLogoutClick = () => {
    setIsPopupOpen(true); // Open the popup
  };
  const handleDelete = async () => {
    try {
      const getTemplateResponse = await axios.get(`${ApiEndPoint}/getTemplate`);
      if (Array.isArray(getTemplateResponse.data.data)) {
        const templatesList = temp;
        console.log("Data wants to delete ", templatesList);
        console.log("Response ", getTemplateResponse);

        getTemplateResponse.data.data.forEach(template => {
          templatesList.forEach(async itemTemplate => {
            if (template.name === itemTemplate.template_name && template.language === itemTemplate.language) {
              console.log("Matched Template ID:", template.id);
              const deleteResponse = await axios.delete(`${ApiEndPoint}/message-templates/`, {
                data: {
                  id: template.id,
                  name: template.name,
                },
              });
              console.log("Response:", deleteResponse.data);
            }
          });
        });

        const deleteBotResponse = await axios.delete(
          `${ApiEndPoint}/deleteBotById/${bot}`
        );
        if (!deleteBotResponse.ok) {
          throw new Error("Network response was not ok");
        }
        setBot('');
        setTemp('');
        setStatus(false);
      }
      console.log("Template Messages:", getTemplateResponse.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  }

  const handleCheckboxClick = (name, currentStatus) => {
    setIsDisabled(true);
    console.log("name", name);
    console.log("status", currentStatus);
    handleToggle(name, currentStatus);
  };

  const handleToggle = async (name, currentStatus) => {
    const newStatus = !currentStatus;
    // Call a function to update the status
    try {
      const response = await fetch(
        `${ApiEndPoint}/updateBotStatus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: name, status: newStatus }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setIsDisabled(false)
    } catch (error) {
      console.error("Error updating bot status:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${ApiEndPoint}/getBotData`);
        const data = await response.json();
        if (data) {
          setItems(data);

          // Run this block only once
          if (!hasRunOnce) {
            const activeBot = data["localJsonData"].find(
              (item) => item.status === true
            );
            if (activeBot) {
              handleClick(activeBot.name);
            }
            else {
              handleClick(data["localJsonData"][0].name);
            }
            setHasRunOnce(true); // Mark that the code has run
          }
        } else {
          setItems([]);
        }
      } catch (error) {
        setItems([]);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, [hasRunOnce]);


  return (
    <div
      style={{
        borderRadius: '10px',
        height: 'auto',
        width: '100%',
        backgroundColor: '#075E54',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap', // Wrap content for small screens
      }}
      className="sidebar-list border-b-2 px-2 py-1 border-black-100 text-sm text-black-100"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem', // Reducing gap between items
          background: 'linear-gradient(135deg, #A66CFF, #8B5CF6)', // Gradient background for a dynamic effect
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Soft shadow to give depth
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.boxShadow = '0px 8px 25px rgba(0, 0, 0, 0.4)'; // Larger shadow on hover
          e.currentTarget.style.transform = 'scale(1.05)'; // Slight enlargement on hover
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'; // Original shadow
          e.currentTarget.style.transform = 'scale(1)'; // Revert to original size
        }}
      >
        <button
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '5px 10px',
            fontWeight: '600',
            cursor: 'pointer',
            backgroundColor: 'transparent', // Transparent background
            border: 'none', // No border
            borderRadius: '8px', // Button with rounded corners
            transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth transitions

          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)'; // Slight background change on hover
            e.currentTarget.style.color = '#FFD700'; // Change text color on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = 'transparent'; // Revert background on mouse leave
            e.currentTarget.style.color = 'white'; // Revert text color
          }}
          onClick={onNew}
        >
          <img src={newchatbot} height={16} width={16} alt="New Bot" />
          <span className="ml-1" style={{ fontSize: 12, color: 'white' }}>New Bot</span>
        </button>
      </div>

      <div style={{ height: '40px', width: '2px', backgroundColor: 'white', margin: '0 15px' }}></div>

      {visible && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            style={{ backgroundColor: 'transparent', border: 'none', }}
            className="text-white font-bold  rounded"
            onClick={onSaveFlow}
            title="Upload Flow"
          >
            <FaCloudUploadAlt style={{ height: 20, width: 20 }} />
          </button>
          <button
            style={{ backgroundColor: 'transparent', border: 'none', marginLeft: 10 }}
            className="text-white font-bold  rounded"
            onClick={onRestoreFlow}
            title="Restore Flow"
          >
            <LuRefreshCcw style={{ height: 20, width: 20 }} />
          </button>
          <button
            style={{ backgroundColor: 'transparent', border: 'none', marginLeft: 10 }}
            className="text-white font-bold  rounded"
            onClick={onDeleteNode}
            title="Delete Node"
          >
            <MdDelete style={{ height: 20, width: 20 }} />
          </button>
        </div>
      )}
      <div style={{ height: '40px', width: '2px', backgroundColor: 'white', margin: '0 15px' }}></div>

      {visible && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            className="card cursor-move d-flex align-items-center justify-content-center rounded hover:bg-primary hover:text-white transition-colors duration-200"
            style={{ backgroundColor: 'transparent', border: 'none', width: '40px', height: '40px' }}
            onDragStart={(event) => onDragStart(event, 'textnode')}
            title="Message Node"
            draggable
          >
            <img src={Message} height={20} width={20} alt="Message Icon" />
          </div>

          <div
            className="card cursor-move d-flex align-items-center justify-content-center rounded hover:bg-primary hover:text-white transition-colors duration-200"
            style={{ backgroundColor: 'transparent', border: 'none', width: '40px', height: '40px' }}
            onDragStart={(event) => onDragStart(event, 'buttons')}
            title="Button Node"
            draggable
          >
            <img src={Button} height={20} width={20} alt="Buttons Icon" />
          </div>

          <div
            className="card cursor-move d-flex align-items-center justify-content-center rounded hover:bg-primary hover:text-white transition-colors duration-200"
            style={{ backgroundColor: 'transparent', border: 'none', width: '40px', height: '40px' }}
            onDragStart={(event) => onDragStart(event, 'message')}
            title="Message Node"
            draggable
          >
            <img src={Template} height={20} width={20} alt="Template Icon" />
          </div>

          <div
            className="card cursor-move d-flex align-items-center justify-content-center rounded hover:bg-primary hover:text-white transition-colors duration-200"
            style={{ backgroundColor: 'transparent', border: 'none', width: '40px', height: '40px' }}
            onDragStart={(event) => onDragStart(event, 'switchAgent')}
            title="Switch to Agent"
            draggable
          >
            <BsPersonBadge size={20} color="white" />
          </div>
        </div>
      )}
      <div style={{ height: '40px', width: '2px', backgroundColor: 'white', margin: '0 15px' }}></div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center', // Centers items vertically
          gap: '1rem',
          // Adds spacing between items
        }}
      >
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-custom-components"
            style={{
              borderRadius: '8px',
              backgroundColor: '#FFFFFF',
              border: '1px solid #A66CFF',
              color: '#25D366',
              padding: '3px 6px',
              cursor: 'pointer',
              outline: 'none',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease',
              width: "110px",
              height: "30px",
              fontSize: "13px",
            }}
          >
            {bot || 'Select Bot'}
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{

              backgroundColor: '#FFFFFF',

              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              width: "150px"
            }}
          >
            {Array.isArray(items["localJsonData"]) && items["localJsonData"].length > 0 ? (
              items["localJsonData"].map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleClick(item["name"])}
                  style={{
                    backgroundColor: '#FFFFFF',
                    color: '#075E54',

                    fontSize: "14px",
                    padding: '8px 12px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#075E54';
                    e.currentTarget.style.color = 'white';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = '#FFFFFF';
                    e.currentTarget.style.color = '#075E54';

                  }}
                >
                  {truncateText(item["name"].toUpperCase(), 10)}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item disabled>
                Loading...
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>


        {visible && (
          <div style={{ display: 'flex' }}>
            <label className="switch">
              <input
                className="form-check-input"
                style={{ backgroundColor: '#A66CFF', cursor: 'pointer' }}
                type="checkbox"
                id="flexSwitchCheckDefault"
                disabled={isDisabled}
                checked={status ? status : false}
                onChange={() => { handleCheckboxClick(bot, status) }
                }
              />
              <span className="slider round"></span>
            </label>

            <button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <MdDelete
                onClick={handleLogoutClick}
                style={{ color: '#FF7640', height: 20, width: 20 }}
              />
            </button>

            <Modal
              isOpen={isPopupOpen}
              onRequestClose={handleCancelLogout}
              style={{
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  padding: '20px',
                  borderRadius: '10px',
                  backgroundColor: '#F5F7FA', // Light background
                  color: '#2E3A59', // Dark text color
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                  textAlign: 'center',
                  maxWidth: '350px', // Limiting width for better design
                  width: '100%',
                },
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.4)', // Lighter overlay
                },
              }}
            >
              <h2 style={{ fontSize: '18px', marginBottom: '15px', fontWeight: '500', color: '#2E3A59' }}>
                Confirm Deletion
              </h2>
              <p style={{ fontSize: '14px', color: '#2E3A59' }}>
                The chatbot will be deleted permanently. Are you sure you want to delete it?
              </p>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button
                  onClick={handleCancelLogout}
                  style={{
                    padding: '10px 20px',
                    marginRight: '10px',
                    borderRadius: '20px',
                    backgroundColor: '#ffffff', // White background for cancel button
                    color: '#2E3A59', // Dark text color for cancel button
                    border: '1px solid #E0E4EB', // Border for cancel button
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: '500',
                    transition: 'background-color 0.3s ease',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f2f5')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#ffffff')}
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmLogout}
                  style={{
                    padding: '10px 20px',
                    borderRadius: '20px', // Rounded buttons for a modern look
                    backgroundColor: '#FF7640', // Orange color for OK button
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: '500',
                    transition: 'background-color 0.3s ease',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#d65a2c')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#FF7640')}
                >
                  OK
                </button>
              </div>
            </Modal>
          </div>
        )}
      </div>
      {/* ... Rest of the code remains the same ... */}
    </div>

  );
}
<style>
  {`
    .sidebar-list {
      gap: 0.25rem; /* Reduce gap between main items */
    }

    @media (max-width: 1024px) {
      .sidebar-list {
        flex-wrap: nowrap; /* Ensure one-row layout */
        justify-content: space-between; /* Distribute items */
      }
      .sidebar-list button img {
        margin-right: 4px; /* Tighten spacing between icon and text */
      }
    }

    @media (max-width: 767px) {
      .sidebar-list {
        flex-wrap: wrap; /* Allow wrapping for small screens */
        gap: 0.5rem; /* Slight gap for better readability */
        justify-content: center; /* Center content */
      }
    }
  `}
</style>





  // return (
  //   <div
  //     style={{
  //       borderRadius: '10px',
  //       height: 'auto',
  //       width: '100%',
  //       backgroundColor: '#075E54',
  //       margin: '0',
  //       display: 'flex',
  //       alignItems: 'center',
  //       justifyContent: 'space-between', // Distributes space between elements
  //       flexWrap: 'wrap', // Ensures wrapping on small screens
  //     }}
  //     className="border-b-2 px-4 py-2 border-black-100 text-sm text-black-100"
  //   >
  //     <div
  //       style={{

  //         background: 'linear-gradient(135deg, #A66CFF, #8B5CF6)', // Gradient background for a dynamic effect
  //         borderRadius: '12px',
  //         boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Soft shadow to give depth
  //         transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  //       }}
  //       onMouseEnter={(e) => {
  //         e.currentTarget.style.boxShadow = '0px 8px 25px rgba(0, 0, 0, 0.4)'; // Larger shadow on hover
  //         e.currentTarget.style.transform = 'scale(1.05)'; // Slight enlargement on hover
  //       }}
  //       onMouseLeave={(e) => {
  //         e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'; // Original shadow
  //         e.currentTarget.style.transform = 'scale(1)'; // Revert to original size
  //       }}
  //     >
  //       <button
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'space-between',
  //           padding: '5px 10px',
  //           fontWeight: '600',
  //           cursor: 'pointer',
  //           backgroundColor: 'transparent', // Transparent background
  //           border: 'none', // No border
  //           borderRadius: '8px', // Button with rounded corners
  //           transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth transitions

  //         }}
  //         onMouseEnter={(e) => {
  //           e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)'; // Slight background change on hover
  //           e.currentTarget.style.color = '#FFD700'; // Change text color on hover
  //         }}
  //         onMouseLeave={(e) => {
  //           e.currentTarget.style.backgroundColor = 'transparent'; // Revert background on mouse leave
  //           e.currentTarget.style.color = 'white'; // Revert text color
  //         }}
  //         onClick={onNew}
  //       >
  //         <img src={newchatbot} height={20} width={20} alt="New Bot" />
  //         <span className="ml-2" style={{ fontSize: 14, color: 'white' }}>New Bot</span>
  //       </button>
  //     </div>

  //     <div style={{ height: '40px', width: '2px', backgroundColor: 'white', margin: '0 15px' }}></div>

  //     {visible && (
  //       <div style={{ display: 'flex', alignItems: 'center' }}>
  //         <button
  //           style={{ backgroundColor: 'transparent', border: 'none', }}
  //           className="text-white font-bold  rounded"
  //           onClick={onSaveFlow}
  //           title="Upload Flow"
  //         >
  //           <FaCloudUploadAlt style={{ height: 20, width: 20 }} />
  //         </button>
  //         <button
  //           style={{ backgroundColor: 'transparent', border: 'none', marginLeft: 10 }}
  //           className="text-white font-bold  rounded"
  //           onClick={onRestoreFlow}
  //           title="Restore Flow"
  //         >
  //           <LuRefreshCcw style={{ height: 20, width: 20 }} />
  //         </button>
  //         <button
  //           style={{ backgroundColor: 'transparent', border: 'none', marginLeft: 10 }}
  //           className="text-white font-bold  rounded"
  //           onClick={onDeleteNode}
  //           title="Delete Node"
  //         >
  //           <MdDelete style={{ height: 20, width: 20 }} />
  //         </button>
  //       </div>
  //     )}
  //     <div style={{ height: '40px', width: '2px', backgroundColor: 'white', margin: '0 15px' }}></div>

  //     {visible && (
  //       <div style={{ display: 'flex', alignItems: 'center' }}>
  //         <div
  //           className="card cursor-move d-flex align-items-center justify-content-center rounded hover:bg-primary hover:text-white transition-colors duration-200"
  //           style={{ backgroundColor: 'transparent', border: 'none', width: '40px', height: '40px' }}
  //           onDragStart={(event) => onDragStart(event, 'textnode')}
  //           title="Message Node"
  //           draggable
  //         >
  //           <img src={Message} height={20} width={20} alt="Message Icon" />
  //         </div>

  //         <div
  //           className="card cursor-move d-flex align-items-center justify-content-center rounded hover:bg-primary hover:text-white transition-colors duration-200"
  //           style={{ backgroundColor: 'transparent', border: 'none', width: '40px', height: '40px' }}
  //           onDragStart={(event) => onDragStart(event, 'buttons')}
  //           title="Button Node"
  //           draggable
  //         >
  //           <img src={Button} height={20} width={20} alt="Buttons Icon" />
  //         </div>

  //         <div
  //           className="card cursor-move d-flex align-items-center justify-content-center rounded hover:bg-primary hover:text-white transition-colors duration-200"
  //           style={{ backgroundColor: 'transparent', border: 'none', width: '40px', height: '40px' }}
  //           onDragStart={(event) => onDragStart(event, 'message')}
  //           title="Message Node"
  //           draggable
  //         >
  //           <img src={Template} height={20} width={20} alt="Template Icon" />
  //         </div>

  //         <div
  //           className="card cursor-move d-flex align-items-center justify-content-center rounded hover:bg-primary hover:text-white transition-colors duration-200"
  //           style={{ backgroundColor: 'transparent', border: 'none', width: '40px', height: '40px' }}
  //           onDragStart={(event) => onDragStart(event, 'switchAgent')}
  //           title="Switch to Agent"
  //           draggable
  //         >
  //           <BsPersonBadge size={20} color="white" />
  //         </div>
  //       </div>
  //     )}
  //     <div style={{ height: '40px', width: '2px', backgroundColor: 'white', margin: '0 15px' }}></div>
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center', // Centers items vertically
  //         gap: '1rem',
  //         // Adds spacing between items
  //       }}
  //     >
  //       <Dropdown>
  //         <Dropdown.Toggle
  //           id="dropdown-custom-components"
  //           style={{
  //             borderRadius: '8px',
  //             backgroundColor: '#FFFFFF',
  //             border: '1px solid #A66CFF',
  //             color: '#25D366',
  //             padding: '3px 6px',
  //             cursor: 'pointer',
  //             outline: 'none',
  //             boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
  //             transition: 'all 0.3s ease',
  //             width: "110px",
  //             height: "30px",
  //             fontSize: "13px",
  //           }}
  //         >
  //           {bot || 'Select Bot'}
  //         </Dropdown.Toggle>

  //         <Dropdown.Menu
  //           style={{

  //             backgroundColor: '#FFFFFF',

  //             boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
  //             width: "150px"
  //           }}
  //         >
  //           {Array.isArray(items["localJsonData"]) && items["localJsonData"].length > 0 ? (
  //             items["localJsonData"].map((item, index) => (
  //               <Dropdown.Item
  //                 key={index}
  //                 onClick={() => handleClick(item["name"])}
  //                 style={{
  //                   backgroundColor: '#FFFFFF',
  //                   color: '#075E54',

  //                   fontSize: "14px",
  //                   padding: '8px 12px',
  //                   cursor: 'pointer',
  //                   transition: 'background-color 0.3s ease',
  //                 }}
  //                 onMouseEnter={(e) => {
  //                   e.currentTarget.style.backgroundColor = '#075E54';
  //                   e.currentTarget.style.color = 'white';
  //                 }}
  //                 onMouseLeave={(e) => {
  //                   e.currentTarget.style.backgroundColor = '#FFFFFF';
  //                   e.currentTarget.style.color = '#075E54';

  //                 }}
  //               >
  //                 {truncateText(item["name"].toUpperCase(), 10)}
  //               </Dropdown.Item>
  //             ))
  //           ) : (
  //             <Dropdown.Item disabled>
  //               Loading...
  //             </Dropdown.Item>
  //           )}
  //         </Dropdown.Menu>
  //       </Dropdown>


  //       {visible && (
  //         <div style={{ display: 'flex' }}>
  //           <label className="switch">
  //             <input
  //               className="form-check-input"
  //               style={{ backgroundColor: '#A66CFF', cursor: 'pointer' }}
  //               type="checkbox"
  //               id="flexSwitchCheckDefault"
  //               disabled={isDisabled}
  //               checked={status ? status : false}
  //               onChange={() => { handleCheckboxClick(bot, status) }
  //               }
  //             />
  //             <span className="slider round"></span>
  //           </label>

  //           <button
  //             style={{
  //               backgroundColor: 'transparent',
  //               border: 'none',
  //               cursor: 'pointer',
  //               display: 'flex',
  //               alignItems: 'center',
  //               justifyContent: 'center',
  //             }}
  //           >
  //             <MdDelete
  //               onClick={handleLogoutClick}
  //               style={{ color: '#FF7640', height: 20, width: 20 }}
  //             />
  //           </button>

  //           <Modal
  //             isOpen={isPopupOpen}
  //             onRequestClose={handleCancelLogout}
  //             style={{
  //               content: {
  //                 top: '50%',
  //                 left: '50%',
  //                 right: 'auto',
  //                 bottom: 'auto',
  //                 marginRight: '-50%',
  //                 transform: 'translate(-50%, -50%)',
  //                 padding: '20px',
  //                 borderRadius: '10px',
  //                 backgroundColor: '#F5F7FA', // Light background
  //                 color: '#2E3A59', // Dark text color
  //                 boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
  //                 textAlign: 'center',
  //                 maxWidth: '350px', // Limiting width for better design
  //                 width: '100%',
  //               },
  //               overlay: {
  //                 backgroundColor: 'rgba(0, 0, 0, 0.4)', // Lighter overlay
  //               },
  //             }}
  //           >
  //             <h2 style={{ fontSize: '18px', marginBottom: '15px', fontWeight: '500', color: '#2E3A59' }}>
  //               Confirm Deletion
  //             </h2>
  //             <p style={{ fontSize: '14px', color: '#2E3A59' }}>
  //               The chatbot will be deleted permanently. Are you sure you want to delete it?
  //             </p>
  //             <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
  //               <button
  //                 onClick={handleCancelLogout}
  //                 style={{
  //                   padding: '10px 20px',
  //                   marginRight: '10px',
  //                   borderRadius: '20px',
  //                   backgroundColor: '#ffffff', // White background for cancel button
  //                   color: '#2E3A59', // Dark text color for cancel button
  //                   border: '1px solid #E0E4EB', // Border for cancel button
  //                   cursor: 'pointer',
  //                   fontSize: '14px',
  //                   fontWeight: '500',
  //                   transition: 'background-color 0.3s ease',
  //                 }}
  //                 onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f2f5')}
  //                 onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#ffffff')}
  //               >
  //                 Cancel
  //               </button>
  //               <button
  //                 onClick={handleConfirmLogout}
  //                 style={{
  //                   padding: '10px 20px',
  //                   borderRadius: '20px', // Rounded buttons for a modern look
  //                   backgroundColor: '#FF7640', // Orange color for OK button
  //                   color: 'white',
  //                   border: 'none',
  //                   cursor: 'pointer',
  //                   fontSize: '14px',
  //                   fontWeight: '500',
  //                   transition: 'background-color 0.3s ease',
  //                 }}
  //                 onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#d65a2c')}
  //                 onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#FF7640')}
  //               >
  //                 OK
  //               </button>
  //             </div>
  //           </Modal>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );

